<template>
  <div v-if="dataLoaded" class="tw-mb-5 tw-relative">
    <!-- <v-container>
        <v-row class="text-left">
          <v-col cols="12">
            <h4 class="tw-text-charcoal tw-text-sm tw-font-bold">
              TRIP STATUS
            </h4>
          </v-col>
          <v-col cols="12">
            <v-card
              class="text-left elevation-0 px-4 pb-7 tw-bg-lighter-grey rounded-30 tw-p-4"
            >
              <v-row>
                <v-col cols="12" class="pb-0">
                  <h3 class="mb-0">Brainstorming</h3>
                </v-col>
                <v-col cols="12" class="py-0">
                  <p class="mb-0">Let's vote on destinations and dates!</p>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="10" class="py-0">
                  <v-progress-linear
                    color="secondary"
                    value="30"
                    height="10"
                    rounded
                  />
                </v-col>
                <v-col cols="2" class="py-0">
                  <img
                    src="@/assets/svgs/my_trips/Airplane.svg"
                    alt="Airplane Icon"
                    width="32"
                    height="32"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container> -->
    <v-container>
      <v-row v-if="showPrompt">
        <v-col cols="12">
          <div
            class="tw-relative tw-border tw-border-j-pink tw-px-3 tw-py-2 tw-w-90 tw-mx-auto tw--mt-4"
          >
            <p
              class="tw-text-left tw-font-bold tw-uppercase tw-text-xs tw-text-j-pink tw-mb-0"
            >
              Enjoying Let's Jetty
            </p>
            <p class="tw-text-left tw-text-xs tw-text-j-pink tw-mb-0">
              Your feedback helps shape the future of our app.
              <a
                href="https://form.jotform.com/240626651051146"
                rel="noopener noreferrer"
                target="_blank"
                class="tw-underline tw-text-j-pink"
                @click="handleClickToPrompt"
                >Click here to answer a quick survey.</a
              >
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="8"
              viewBox="0 0 6 8"
              fill="none"
              class="tw-absolute tw-right-2 tw-top-2 tw-cursor-pointer"
              @click="closePrompt()"
            >
              <path
                d="M6 1.54194L5.39571 0.863281L3 3.55385L0.604286 0.863281L0 1.54194L2.39571 4.23251L0 6.92308L0.604286 7.60174L3 4.91117L5.39571 7.60174L6 6.92308L3.60429 4.23251L6 1.54194Z"
                fill="#E24BD6"
              />
            </svg>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="tw-flex tw-items-center tw-justify-between tw-mb-3">
            <h4 class="tw-text-charcoal tw-text-sm text-left tw-font-bold">
              SHARED NOTES & LISTS
            </h4>
            <router-link
              class="tw-text-light-blue tw-underline tw-m-0 tw-text-sm"
              :to="{
                name: 'NotesListRoute',
                params: { id: $route.params.id }
              }"
              >View all notes</router-link
            >
          </div>

          <div
            class="tw-p-5 tw-flex tw-justify-between tw-items-center tw-border-dashed tw-border-2 tw-rounded tw-border-light-blue tw-cursor-pointer"
            @click="$router.push({ name: 'NoteCreate' })"
            v-if="notes.length === 0"
          >
            <p
              class="tw-text-13 tw-font-bold tw-text-light-blue tw-uppercase tw-m-0"
            >
              Create your first note
            </p>
            <v-btn
              class="tw-rounded-full tw-bg-light-blue tw-text-white tw-shadow-md tw-tracking-normal tw-w-6 tw-h-6 min-w-auto tw-p-1"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
          <template v-else>
            <div
              v-for="note in notes"
              :key="note.id"
              class="tw-shadow-bottom tw-rounded tw-bg-lighter-grey tw-p-4 tw-cursor-pointer tw-mb-4 tw-relative tw-flex tw-items-center"
              @click="
                $router.push({
                  name: 'NoteEdit',
                  params: { id: $route.params.id, noteId: note.id }
                })
              "
            >
              <div>
                <span
                  class="tw-text-sm tw-text-dark-green tw-text-left tw-block tw-uppercase tw-font-bold tw-m-0 tw-leading-5"
                  >{{ note.title }}</span
                >
                <p
                  class="tw-text-sm tw-text-left tw-m-0 tw-text-light-blue"
                  v-if="note.isLockedBy && lockedByUsers[note.isLockedBy]"
                >
                  {{
                    `${lockedByUsers[note.isLockedBy].fullName} is currently editing`
                  }}
                </p>
                <p
                  class="tw-text-xs tw-text-left tw-m-0 tw-text-light-grey"
                  v-else
                >
                  {{ `Updated at: ${getFormattedDate(note.updatedAt)}` }}
                </p>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="13"
                viewBox="0 0 7 13"
                fill="none"
                class="tw-absolute tw-right-4"
              >
                <path
                  d="M-4.55911e-07 1.52133L4.32659 6.11133L-6.0702e-08 10.7013L1.33198 12.1113L7 6.11133L1.33198 0.111328L-4.55911e-07 1.52133Z"
                  fill="#949494"
                />
              </svg>
            </div>
          </template>
          <div
            class="j-premium tw-flex tw-align-center tw-text-white tw-pl-3 tw-mt-4 tw-rounded tw-pt-4 tw-pb-5 tw-shadow-bottom tw-cursor-pointer"
            @click="$router.push({ name: 'UpgradeTripRoute' })"
            v-if="!trip.isPremium"
          >
            <img
              src="@/assets/svgs/premium/white-diamond-premium.svg"
              alt="Premium"
              class="tw-mt-1 tw-mr-3 tw-w-3 tw-h-3"
            />
            <p class="tw-uppercase tw-m-0 tw-font-bold tw-leading-5 tw-text-13">
              Upgrade to Access Unlimited Notes
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h4 class="tw-text-charcoal tw-text-sm text-left tw-font-bold">
            DETAILS
          </h4>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-h-full tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-royal-blue tw-transition-opacity hover:tw-opacity-90 tw-flex tw-items-end"
            @click.prevent="
              $router.push({
                name: 'DestinationsRoute',
                params: { tripId: trip.id }
              })
            "
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-white tw-text-13 tw-uppercase"
            >
              {{ destinationName }}
            </h3>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-h-full tw-pt-7 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-j-pink tw-transition-opacity hover:tw-opacity-90 tw-flex tw-items-end"
            :class="{ 'tw-pt-7': daysToGo !== null }"
            @click="openDatesRoute()"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-white tw-text-13 tw-flex tw-items-center tw-flex-wrap"
              :class="{ 'tw-w-full': daysToGo !== null }"
            >
              <span class="tw-uppercase" :class="getSpanSize()">{{
                daysToGo === null ? "DATES" : daysToGo
              }}</span>
              <span
                class="tw-mt-2 tw-text-xs tw-w-full tw-text-center"
                v-if="daysToGo !== null"
                >{{ daysToGo === 1 ? "DAY" : "DAYS" }} TO GO</span
              >
            </h3>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-relative tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-chartreuse tw-transition-opacity hover:tw-opacity-90"
            v-if="hasSurvey"
            :class="travelWidgetPadding"
            @click.prevent="goTo({ name: 'SurveyListRoute' })"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-dark-green tw-text-13"
            >
              TRIP SURVEY
            </h3>
            <img
              class="tw-absolute tw-w-12 tw-right-2 tw-top-3"
              src="@/assets/svgs/premium/diamonds-green.svg"
              alt="Multiple Surveys"
              v-if="trip.isPremium"
            />
          </div>
          <div
            class="tw-relative tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-transition-opacity"
            v-else
            :class="isOwner || isOrganizer ? 'j-active' : 'j-disabled'"
            @click.prevent="shouldGoTo()"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-dark-green tw-text-13"
            >
              {{ isOwner || isOrganizer ? "ADD SURVEY" : "TRIP SURVEY" }}
            </h3>
            <img
              class="tw-absolute tw-right-2 tw-top-4 tw-z-10"
              src="@/assets/svgs/more-info-icon.svg"
              alt="More info trip survey"
              id="infoIcon"
              @click="showInviteeTripInfo = !showInviteeTripInfo"
              v-if="!isOwner && !isOrganizer"
            />
            <img
              class="tw-absolute tw-w-12 tw-right-2 tw-top-3"
              src="@/assets/svgs/premium/diamonds-green.svg"
              alt="Multiple Surveys"
              v-if="trip.isPremium && (isOwner || isOrganizer)"
            />
          </div>
        </v-col>
        <div
          class="tw-bg-white tw-p-4 tw-absolute tw-text-sm tw--top-500 tw-w-200 tw-left-70 tw-top-18"
          id="infoContainer"
          v-if="showInviteeTripInfo"
        >
          <p class="tw-text-left tw-m-0">
            Your trip organizer may create a survey to ask about travel
            preferences & other things. Be sure email notifications are enabled
            to get notified if they do.
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="12"
            viewBox="0 0 16 12"
            fill="none"
            class="tw-absolute tw--bottom-2 tw-mx-auto tw-left-0 tw-right-0"
          >
            <path d="M15.9277 0L7.92773 11.25L0 0H15.9277Z" fill="white" />
          </svg>
        </div>
        <v-col cols="6">
          <div
            class="tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-lavendar tw-transition-opacity hover:tw-opacity-90"
            @click.prevent="goTo({ name: 'AccommodationList' })"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-dark-green tw-text-13"
            >
              {{ finalizedAccommodation ? "VIEW YOUR STAY" : "PLACES TO STAY" }}
            </h3>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="tw-relative tw-px-4 tw-py-5 tw-rounded-md tw-cursor-pointer tw-bg-light-blue tw-transition-opacity hover:tw-opacity-90"
            @click.prevent="inviteCrew()"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-white tw-text-13"
            >
              THE CREW & INVITES
            </h3>
            <img
              class="tw-absolute tw-w-12 tw-right-2 tw-top-3"
              src="@/assets/svgs/premium/diamonds-white.svg"
              alt="Multiple Surveys"
              v-if="trip.isPremium"
            />
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-j-green tw-transition-opacity hover:tw-opacity-90"
            :class="travelWidgetPadding"
            @click.prevent="
              goTo({
                name: 'TripItineraryCreate',
                params: { value: events.TRAVEL }
              })
            "
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-white tw-text-13"
            >
              ADD TRAVEL DETAILS
            </h3>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-cursor-pointer tw-bg-royal-blue tw-transition-opacity hover:tw-opacity-90"
            @click.prevent="goTo({ name: 'ActivitiesRoute' })"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-white tw-text-13"
            >
              THINGS TO DO
            </h3>
          </div>
        </v-col>
        <v-col cols="12">
          <div
            class="tw-px-4 tw-py-5 tw-rounded-md tw-cursor-pointer tw-bg-chartreuse tw-transition-opacity hover:tw-opacity-90"
            @click.prevent="goTo({ name: 'TravelSchedulesListRoute' })"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-dark-green tw-text-13"
            >
              VIEW TRAVEL SCHEDULES
            </h3>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pb-0">
          <h4 class="tw-text-charcoal tw-text-sm text-left tw-font-bold">
            FROM OUR PARTNERS
          </h4>
          <p class="text-left text-muted mb-0 tw-text-xs">
            Partner links may result in us earning a commission or fee at no additional cost to you.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <faye-button link="https://www.withfaye.com/?utm_medium=gd-partnerships&utm_source=letsjetty&utm_campaign=general_us&utm_content=triphub" />
      </v-row>

      <v-row v-if="false">
        <v-col cols="12">
          <h4 class="tw-text-charcoal tw-text-sm text-left tw-font-bold">
            FEATURES COMING SOON!
          </h4>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="6">
          <div
            class="tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-bg-mid-grey"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-charcoal tw-text-13"
            >
              GROUP CHAT
            </h3>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="tw-pt-14 tw-pr-4 tw-pb-4 tw-pl-4 tw-rounded-md tw-bg-mid-grey"
          >
            <h3
              class="tw-text-left tw-font-figtree tw-font-bold tw-text-charcoal tw-text-13"
            >
              SMS NOTIFICATIONS
            </h3>
          </div>
        </v-col>
      </v-row>

      <v-dialog content-class="custom-premium" v-model="showPremiumModal">
        <div class="tw-bg-white tw-p-5 tw-relative">
          <img
            src="@/assets/svgs/circle-close.svg"
            alt="Close"
            class="tw-absolute tw-right-5 tw-top-5 tw-cursor-pointer"
            @click="
              showPremiumModal = false;
              showPremiumInfoModal = false;
            "
          />
          <img
            src="@/assets/svgs/premium/letsjetty-premium.svg"
            alt="Premium Trip"
            class="tw-w-100 md:tw-w-auto"
          />
          <h1
            class="tw-text-18 md:tw-text-22 tw-font-semibold tw-text-dark-green tw-my-4 md:tw-my-6 tw-text-left"
            v-if="showPremiumInfoModal"
          >
            First Class trips unlock <br />
            more planning power.
          </h1>
          <h1
            class="tw-text-18 md:tw-text-22 tw-font-semibold tw-text-dark-green tw-my-4 md:tw-my-6 tw-text-left"
            v-else
          >
            Congrats! Your trip was <br />
            upgraded to first class.
          </h1>
          <div class="tw-flex tw-items-start tw-mb-2 md:tw-mb-4">
            <img
              src="@/assets/svgs/premium/customized-invitations.svg"
              alt="Customized invitations"
              class="tw-mr-2"
            />
            <div class="tw-text-left">
              <p class="tw-m-0 tw-font-semibold tw-text-charcoal">
                Customize your invitation
              </p>
              <p class="tw-text-light-grey tw-text-sm">
                Choose from a custom design to spice up your trip invite.
              </p>
            </div>
          </div>
          <div class="tw-flex tw-items-start tw-mb-2 md:tw-mb-4">
            <img
              src="@/assets/svgs/premium/multiple-surveys.svg"
              alt="Multiple Surveys"
              class="tw-mr-2"
            />
            <div class="tw-text-left">
              <p class="tw-m-0 tw-font-semibold tw-text-charcoal">
                Unlimited notes & surveys
              </p>
              <p class="tw-text-light-grey tw-text-sm">
                Get all of your questions answered by sending out as many
                surveys as your heart desires.
              </p>
            </div>
          </div>
          <div class="tw-flex tw-items-start tw-mb-2 md:tw-mb-4">
            <img
              src="@/assets/svgs/premium/first-access.svg"
              alt="First Access"
              class="tw-mr-2"
            />
            <div class="tw-text-left">
              <p class="tw-m-0 tw-font-semibold tw-text-charcoal">
                Be the first
              </p>
              <p class="tw-text-light-grey tw-text-sm">
                You’ll be there first to access our new premium features before
                anyone else.
              </p>
            </div>
          </div>
          <v-btn
            primary
            x-large
            class="j-premium tw-rounded-3xl tw-w-full tw-text-white tw-mb-4 tw-font-semibold"
            @click="
              showPremiumModal = false;
              showPremiumInfoModal = false;
            "
            >Let's Go!</v-btn
          >
        </div>
      </v-dialog>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12">
          <p
            class="tw-text-l-grey tw-text-left tw-my-8 tw-text-sm tw-font-bold"
          >
            TRIP SETTINGS
          </p>
          <div
            class="tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-cursor-pointer"
            @click="makePremiumAction()"
          >
            <img
              src="@/assets/svgs/premium/blue-diamond-solo.svg"
              class="tw-mr-3"
              alt="First class trip benefits"
            />
            <p class="tw-m-0 tw-text-black">
              {{
                trip.isPremium
                  ? "First Class Trip Benefits"
                  : "Upgrade Trip to First Class"
              }}
            </p>
            <img
              src="@/assets/svgs/premium/arrow-forward.svg"
              class="tw-absolute tw-right-2"
              alt="See"
            />
          </div>
          <div
            class="tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-mt-4 tw-cursor-pointer"
            @click="$router.push({ name: 'Notifications' })"
          >
            <img
              src="@/assets/svgs/notify-me.svg"
              class="tw-mr-2"
              alt="Notify me"
            />
            <p class="tw-m-0 tw-text-black">Notify me</p>
            <img
              src="@/assets/svgs/premium/arrow-forward.svg"
              class="tw-absolute tw-right-2"
              alt="Cancel Trip"
            />
          </div>
          <div
            class="tw-flex tw-pb-2 tw-items-center tw-relative tw-border-b tw-border-warm-grey-200 tw-mt-4 tw-cursor-pointer"
            @click="
              $router.push({
                name: 'CancelConfirmationRoute',
                params: { id: $route.params.id }
              })
            "
            v-if="isOwner"
          >
            <v-icon class="tw-text-warm-grey tw-text-18 tw-mr-2"
              >mdi-calendar-remove-outline</v-icon
            >
            <p class="tw-m-0 tw-text-black">Cancel Trip</p>
            <img
              src="@/assets/svgs/premium/arrow-forward.svg"
              class="tw-absolute tw-right-2"
              alt="Cancel Trip"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { EVENTS } from "@/enums/events.js";
import FayeButton from '../components/partners/FayeButton.vue';

export default {
  components: { FayeButton },
  name: "TripView",
  mixins: [FormattedDateRange],
  data() {
    return {
      showPremiumModal: false,
      showPremiumInfoModal: false,
      hasSurvey: false,
      destinationName: "DESTINATION",
      finalizedAccommodation: null,
      events: EVENTS,
      travelWidgetPadding: "tw-pt-14",
      showPrompt: false,
      showInviteeTripInfo: false,
      dataLoaded: false,
      lockedByUsers: {}
    };
  },
  watch: {
    trip: {
      handler: function (val) {
        if (val.surveys && val.surveys.length > 0) {
          this.hasSurvey = true;
        } else {
          this.hasSurvey = false;
        }
      },
      deep: true
    }
  },
  computed: {
    notes() {
      if (
        this.trip.isPremium &&
        this.trip.notes &&
        this.trip.notes.length > 0
      ) {
        const pinnedNotes = this.trip.notes.filter(o => o.isPinned)
        if (pinnedNotes.length > 0) {
          return pinnedNotes
        } else {
          return this.trip.notes.slice(0, 3);
        }
      } else if (
        !this.trip.isPremium &&
        this.trip.notes &&
        this.trip.notes.length > 0
      ) {
        return this.trip.notes.slice(0, 1);
      } else {
        return [];
      }
    },
    sessionUser() {
      return this.$store.state.auth.user;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      return this.$store.state.auth.user.id === this.trip.ownerId;
    },
    isOrganizer() {
      let currentTrip = this.$store.state.auth.user.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    },
    daysToGo() {
      if (this.trip.startDate) {
        const now = DateTime.now();
        const start = DateTime.fromISO(this.trip.startDate);
        const diff = start.diff(now, "days").toObject();
        const count = Math.round(diff.days);

        if (count >= 1) {
          return count;
        } else {
          return 0;
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    getNoteSubtitle(note) {
      if (note.isLocked) return `${note.isLocked} is currently editing`;
      return `Updated at: ${this.getFormattedDate(note.updatedAt)}`;
    },
    handleResize() {
      let screenWidth = window.innerWidth;
      if (screenWidth <= 440) {
        this.travelWidgetPadding = "tw-pt-9";
      } else {
        this.travelWidgetPadding = "tw-pt-14";
      }
    },
    getDateString(startDate, endDate) {
      if (startDate && endDate)
        return `${DateTime.fromISO(startDate).toLocaleString(
          DateTime.DATE_SHORT
        )} - ${DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_SHORT)}`;
      return "TBD";
    },
    shouldGoTo() {
      if (this.isOwner || this.isOrganizer)
        this.goTo({ name: "SurveyCreateRouteForm" });
    },
    goTo(route) {
      if (route.name === "SurveyCreateRouteForm") {
        sessionStorage.setItem("clicked_survey_creation", this.$route.path);
      }

      this.$router.push(route);
    },
    inviteCrew() {
      if (localStorage.getItem("tripData")) {
        this.goTo({
          name: "CreateInvitationForm",
          params: { id: this.trip.id }
        });
      } else {
        this.goTo({ name: "CrewListRoute", params: { id: this.trip.id } });
      }
    },
    openDatesRoute() {
      if (this.trip.startDate) {
        this.goTo({ name: "DatesListRoute" });
      } else {
        this.goTo({ name: "DatesCreateRoute" });
      }
    },
    getSpanSize() {
      if (this.daysToGo !== null) {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 389) {
          return "tw-text-xl tw-text-center tw-w-full";
        } else {
          return "tw-leading-8 tw-text-64 tw-text-center tw-w-full";
        }
      } else {
        return "tw-text-sm";
      }
    },
    setDates() {
      // After trip details are fetched and updated in the store, set the dates
      if (this.trip && this.trip.startDate && this.trip.endDate) {
        this.$store.commit("meta/setHeader", {
          ...this.$store.state.meta.header,
          startDate: DateTime.fromISO(this.trip.startDate, {
            setZone: true
          }).toISODate(),
          endDate: DateTime.fromISO(this.trip.endDate, {
            setZone: true
          }).toISODate()
        });
      }
    },
    async makePremiumAction() {
      if (this.trip.isPremium) {
        this.showPremiumInfoModal = true;
        this.showPremiumModal = true;
      } else {
        if (
          this.sessionUser.user_wallet &&
          this.sessionUser.user_wallet.credits > 0
        ) {
          this.goTo({ name: "UpgradeTripRoute", params: { id: this.trip.id } });
        } else {
          this.goTo({
            name: "UpgradeTripPurchaseRoute",
            params: { id: this.trip.id }
          });
        }
      }
    },
    async setPremiumData(trip) {
      let tripUser = trip.users.filter(
        (user) => user.id === this.sessionUser.id
      )[0];

      if (
        tripUser.trip_invite &&
        !tripUser.trip_invite.isPremiumTripDialogueRead
      ) {
        this.showPremiumModal = true;
        await this.$store.dispatch("trip-invite/patch", [
          tripUser.trip_invite.id,
          {
            isPremiumTripDialogueRead: true
          }
        ]);
      }
    },
    setPageTitle() {
      // After trip details are fetched and updated in the store, set the pageTitle
      if (this.trip && this.trip.title) {
        this.$store.commit("meta/setHeader", {
          ...this.$store.state.meta.header,
          pageTitle: this.trip.title
        });
      }
    },
    setDestination() {
      //After trip details are fetched and updated in the store, set the destination
      if (this.trip && this.trip.destinations[0]) {
        this.$store.commit("meta/setHeader", {
          ...this.$store.state.meta.header,
          destination: this.trip.destinations[0].name
        });
      }
    },
    closePrompt() {
      this.showPrompt = false;
      localStorage.setItem("promptShown", true);
    },
    handleClickToPrompt(e) {
      e.preventDefault();
      this.closePrompt();
      const href = event.target.getAttribute("href");
      window.open(href, "_blank");
    },
    handleInfoClick(e) {
      let container = document.getElementById("infoContainer");
      let arrow = document.getElementById("infoIcon");
      let target = e.target;

      // Check if the clicked element is inside the container
      if (container && !container.contains(target)) {
        if (!arrow.contains(target)) {
          this.showInviteeTripInfo = false;
        }
      }
    },
    async fetchUsersData() {
      for (let note of this.trip.notes) {
        if (note.isLocked) {
          const user = await this.$store.dispatch("users/get", note.isLockedBy);
          this.$set(this.lockedByUsers, note.isLockedBy, user);
        }
      }
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "My Trips",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      tripHubButton: true,
      endDate: null
    });

    // Dispatch the action to get trip details
    this.$store
      .dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation,note"
          }
        }
      ])
      .then((res) => {
        //destination name
        if (res.destinations[0])
          this.destinationName = res.destinations[0].name;

        if (res.surveys && res.surveys.length > 0) {
          this.hasSurvey = true;
        }

        if (this.trip.notes && this.trip.notes.length > 0)
          this.trip.notes.sort((a, b) => {
            if (a.isPinned === b.isPinned) {
              return new Date(b.updatedAt) - new Date(a.updatedAt);
            } else {
              return a.isPinned ? -1 : 1;
            }
          });

        this.setPageTitle();
        this.setDates();
        this.setDestination();

        //check premium
        if (res.isPremium) {
          this.setPremiumData(res);
        }

        this.$store
          .dispatch("accommodation/find", {
            query: {
              tripId: this.$route.params.id
            }
          })
          .then((res) => {
            if (res.data.length > 0) this.finalizedAccommodation = res.data[0];
          });

        this.fetchUsersData();

        this.dataLoaded = true;
      })
      .catch((error) => {
        console.error("Error fetching trip details:", error);
      });
  },
  mounted() {
    document.addEventListener("click", this.handleInfoClick);
    window.addEventListener("resize", this.handleResize);
    if (!localStorage.getItem("promptShown")) {
      this.showPrompt = true;
    }
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("click", this.handleInfoClick);
    this.showInviteeTripInfo = false;
  }
};
</script>
<style lang="scss">
.j-disabled {
  @apply tw-bg-warm-grey tw-text-dark-green tw-opacity-60;
}
.j-active {
  @apply tw-bg-chartreuse;

  @media screen and (min-width: 1023px) {
    &:hover {
      opacity: 90%;
    }
  }
}

.text-muted {
  color: #949494;
}

.custom-premium {
  border-radius: 24px;
  width: 400px;
}

.tw-left-70 {
  left: 70px;
}

.min-w-auto {
  min-width: auto !important;
}

.tw-shadow-bottom {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.09);
}
</style>
